<template lang="html" src="./pageBcmPart.template.vue"></template>

<style lang="scss" src="./pageBcmPart.scss"></style>

<script>
import {
  ApiErrorParser,
} from '@cloudmanufacturingtechnologies/portal-components';

import SupportEmail from '../../components/supportEmail/SupportEmail';

const i18nData = require('./pageBcmPart.i18n');

export default {
  name: 'PageBcmPart',
  components: {
    SupportEmail,
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      part: null,
      tabs: [
        {
          name: this.$t('Overview'),
          route: { name: 'BcmPartOverview'},
          icon: '',
        },
        {
          name: this.$t('Order'),
          route: { name: 'BcmPartOrder'},
          icon: '',
        },
        {
          name: this.$t('Production'),
          route: { name: 'BcmPartProduction'},
          icon: '',
        },
        {
          name: this.$t('Attachments'),
          route: { name: 'BcmPartAttachments'},
          icon: '',
        }
      ]
    };
  },
  updated() {
    if(this.$route.name === 'BcmPart') {
      this.$router.push({name: 'BcmLibrary'});
    }
  },
  created() {
    // Need to see if no duplicate route, dont remove this comment
    // (this.$route.name !== 'BcmPartOverview') {
    //   this.$router.push(this.tabs[0].route);
    // }
    this.getSupplierPart();
  },
  methods: {
    /**
     * GET SUPPLIER PART
     */
    getSupplierPart() {
      this.$apiInstance
        .getSupplierPart(
          this.$route.params.supplierUUID,
          this.$route.params.partUUID
        )
        .then(
          (partData) => {
            this.part = partData;
          },
          /**
           * ERROR GET SUPPLIER PART
           */
          (error) => {
            this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
          }
        );
    },
  },
};
</script>
