var render = function () {
  var _vm$part, _vm$part2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.part ? _c('div', {
    staticClass: "page-part"
  }, [_vm.part ? _c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', [_c('v-row', {
    staticClass: "text-wrap display-1 partTitle mx-0 my-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('Part')) + " " + _vm._s(_vm.part.name) + " "), _vm.part.status === 'NEW' ? _c('v-chip', {
    staticClass: "mt-1 ml-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('new')) + " ")]) : _vm._e(), _vm.part.status === 'HOMOLOGATED' ? _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-img', _vm._g({
          staticClass: "homologatedIcon ml-2",
          attrs: {
            "src": require("../../../public/img/icons/validHomologation-primary.svg"),
            "alt": "homologated icon",
            "max-width": "35px",
            "max-height": "35px"
          }
        }, on))];
      }
    }], null, false, 3779177337)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('homologatedPart')))])]) : _vm._e()], 1)], 1), _c('v-col', {
    attrs: {
      "align": "end"
    }
  }, [_c('v-btn', {
    staticClass: "orange text-none white--text mr-2",
    attrs: {
      "ripple": false,
      "to": {
        name: 'Part',
        params: {
          brandUUID: "".concat((_vm$part = _vm.part) === null || _vm$part === void 0 ? void 0 : _vm$part.brand_id),
          partUUID: "".concat((_vm$part2 = _vm.part) === null || _vm$part2 === void 0 ? void 0 : _vm$part2._id),
          supplierUUID: "".concat(_vm.$route.params.supplierUUID),
          customerUUID: "".concat(_vm.$route.params.customerUUID)
        }
      }
    }
  }, [_c('v-icon', {
    staticClass: "ma-3",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-cog ")]), _vm._v(" " + _vm._s(_vm.$t('parameters')) + " ")], 1), _c('v-btn', {
    staticClass: "info text-none",
    attrs: {
      "ripple": false
    },
    on: {
      "click": function click($event) {
        _vm.$refs.support.dialogSupportEmail = true;
      }
    }
  }, [_c('v-icon', {
    staticClass: "ma-3",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-headset ")]), _vm._v(" " + _vm._s(_vm.$t('contactSupport')) + " "), _vm.part ? _c('SupportEmail', {
    ref: "support",
    attrs: {
      "pre-subject": _vm.part.homologation.status === 'SUPPLIER_QUOTATION' ? "[SQ-".concat(_vm.part._id, "]") : "[VQ-".concat(_vm.part._id, "]"),
      "display-button": false
    }
  }) : _vm._e()], 1)], 1)], 1) : _vm._e(), _c('v-divider', {
    staticClass: "mt-0 mb-4"
  }), _c('v-row', {
    staticClass: "mx-0 pa-0 mt-2"
  }, [_c('v-card', {
    staticClass: "card-tabs"
  }, [_c('v-tabs', {
    attrs: {
      "slider-color": "orange",
      "grow": "",
      "slider-size": "4"
    }
  }, _vm._l(_vm.tabs, function (tab, key) {
    return _c('v-tab', {
      key: key,
      staticClass: "text-none",
      attrs: {
        "to": tab.route,
        "ripple": false,
        "exact": ""
      }
    }, [tab.icon ? _c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "small": ""
      }
    }, [_vm._v(" " + _vm._s(tab.icon) + " ")]) : _vm._e(), _vm._v(" " + _vm._s(tab.name) + " ")], 1);
  }), 1)], 1)], 1), _c('v-tabs-items', {
    staticClass: "tabs-items-content mt-3 elevation-3 px-5 rounded"
  }, [_c('router-view')], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }